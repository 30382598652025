<template>
  <div class="container-warp">
    <div class="top-box">
      <back-title>课程名称</back-title>
      <div class="top-box-info">
        <div class="top-box-info-left">
          <img src="@/assets/img/login-bg.jpg" alt="" />
          <div class="top-box-info-left-xx">
            <div class="top-box-info-left-bt">
              【鉴定知识】贵金属鉴定学习<span>入职培训</span>
            </div>
            <div class="top-box-info-left-kc">课程介绍</div>
          </div>
        </div>
        <div class="top-box-info-right">
          <el-button class="add-group-btn" @click="startStudy">开始学习</el-button>
        </div>
      </div>
      <div class="course-introduce-content">
        <com-title>课程介绍内容</com-title>
        <div class="conetnt-item">ddddddddddd</div>
      </div>
    </div>
    <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="315px"
    :before-close="handleClose">
    <div slot="title" class="header-title">
      <svg
        t="1700015762243"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3163"
        width="19.2"
        height="19.2"
      >
        <path
          d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024zM460.8 278.755556a51.2 51.2 0 1 1 102.4 0v295.822222a51.2 51.2 0 1 1-102.4 0V278.755556z m102.4 455.111111a51.2 51.2 0 1 1-102.4 0 51.2 51.2 0 0 1 102.4 0z"
          fill="#FAAD14"
          p-id="3164"
        ></path>
      </svg>
      <span>确认开始学习</span>
    </div>
    <span>开始学习后，系统将自动把当前课程添加到【正在学习】列表，以方便您查看学习</span>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="defineStudy">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import BackTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: {
    BackTitle,
    comTitle
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    startStudy () {
      // 开始学习
      this.dialogVisible = true
    },
    defineStudy () {
      this.$router.push({
        path: '/myOnlineCourseDetails'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  padding: 16px 24px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .top-box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-box-info-left {
      display: flex;
      img {
        margin-right: 16px;
        width: 120px;
        height: 90px;
        border-radius: 4px;
      }
      .top-box-info-left-xx {
        .top-box-info-left-bt {
          margin: 4px 0;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          span {
            display: inline-block;
            padding: 3px 12px;
            margin-left: 16px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 8px 0px 8px 0px;
            background: linear-gradient(to right, #31adf6, #5096ff);
          }
        }
        .top-box-info-left-kc {
          margin-top: 16px;
          font-size: 14px;
          color: #666666;
        }
        .top-box-info-left-mc {
          font-size: 12px;
          color: #979797;
        }
      }
    }
  }
}
.add-group-btn {
  width: 88px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.course-introduce-content{
    min-height: 620px;
    background: #FFFFFF;
    padding-top: 24px;
    .conetnt-item{
        font-size: 14px;
        color: #4D4D4D;
        margin-top: 8px;
    }
}
/deep/ .header-title{
    display: flex;
    align-items: center;
    svg{
        margin-right: 10px;
    }
}
/deep/.el-dialog__body{
    padding:10px 28px 24px 52px !important;
}
</style>
